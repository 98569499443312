<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type"
        [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <app-alerts></app-alerts>

          <div class="row">
            <div class="col-sm-12 mt-2">
              <div class="card b-r-0 mb-0">

            

                <div class="card-header">

                  <svg style="width: 100%; height: 45px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                    <defs>
                    <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255, 0, 255, 1)"></stop><stop offset="100%" stop-color="rgba(255, 0, 255, 0)"></stop></radialGradient>
                    <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(255, 255, 0, 0)"></stop></radialGradient>
                    <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0, 255, 255, 1)"></stop><stop offset="100%" stop-color="rgba(0, 255, 255, 0)"></stop></radialGradient>
                    <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(0, 255, 0, 0)"></stop></radialGradient>
                    <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0,0,255, 1)"></stop><stop offset="100%" stop-color="rgba(0,0,255, 0)"></stop></radialGradient>
                    <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255,0,0, 1)"></stop><stop offset="100%" stop-color="rgba(255,0,0, 0)"></stop></radialGradient>
                    </defs>
                    <rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
                    <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
                    </rect>
                    <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
                    </rect>
                    </svg>

                  <h5 style="margin-top:-40px;">
                    <ng-container
                      *ngIf=" dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO && dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url && dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url.length > 5 ">
                      <img width="30" height="30"
                        src="{{  dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url   }}">
                    </ng-container>
                    {{ dataHolderService.application_configuration.CONFIGURATION_COMPANY_NAME ?  dataHolderService.application_configuration.CONFIGURATION_COMPANY_NAME.value : '' }}
                    <br>
                    <small class=""> <small>  <i>Hi {{ dataHolderService.userInfo ? dataHolderService.userInfo.name  : '' }},  Welcome to BERP </i> </small>   </small>  
                  </h5>

   
                </div>

              </div>

            </div>
          </div>



          <br>

          <router-outlet #o="outlet"></router-outlet>

        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<!-- page-wrapper End-->