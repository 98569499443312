import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  type : string = 'info';

  active : boolean = false;

  message : string = '';

  messageInterval : number = 5000;

  constructor() { }

  // type - info , success , danger

  addAlert(type, message) : void{
    this.active = false;
    this.message = message;
    this.type = type;
    this.active = true;

    if(type!='info'){
      setTimeout(() => { this.removeAlert(); }, this.messageInterval);

    }

  }

  removeAlert() : void{
    this.active = false;
  }
}
