<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD ITEM' : 'UPDATE ITEM' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm">

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>

                                

                        </div>


                        <div class="form-row">

                            <div *ngIf="!isCreate" class="col-md-6 mb-3">
                                <label for="id">Record ID : </label>

                                <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                    formControlName="id" readonly>

                            </div>

                            <div class="col-md-6 mb-3">

                                <label for="item_order_date">Item Order Date : </label>
                                <input class="form-control" id="item_order_date" type="date" placeholder="Item Order Date"
                                    formControlName="item_order_date" name="item_order_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['item_order_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                        </div>


                        <div class="form-row">


                            <div class="col-md-4 mb-3">
                                <label for="title"> Title (Item Small Description) : </label>
                                <input class="form-control" id="title" formControlName="title" name="title" />
                                <p> A smal description about this booking item. You can display this information in Vouchers / Invoices  </p>
                                <div class="form-error-message">

                                </div>

                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="item_id">Item or Material</label>
                                <select class="form-control" id="item_id" type="text" placeholder="Item or Material"
                                    formControlName="item_id" name="item_id">
                                    <option value="-1">NA</option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.available_booking_items)"
                                        [value]="dataHolderService.available_booking_items[key].id">
                                        {{ dataHolderService.available_booking_items[key].name }}
                                    </option>
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.item_id == '-1'">
                                        *Please select Item
                                    </span>
                                </div>

                                <p><a routerLink="/settings/available-booking-items/create"><small>Add new Item /
                                            Material </small></a></p>


                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="taxes_applicable">Taxes Applicable?</label>
                                <select class="form-control" id="taxes_applicable" type="text"
                                    placeholder="Taxes Applicable?" formControlName="taxes_applicable"
                                    name="taxes_applicable">

                                    <option [value]="0">No</option>
                                    <option [value]="1">Yes </option>



                                </select>

                                <p *ngIf="isCreate && operationsForm.value.taxes_applicable == '1'"> You can add taxes in the next step </p>

                                <p *ngIf="!isCreate && operationsForm.value.taxes_applicable == '1'"> Scroll to the bottom to add taxes to this item </p>

                                <p *ngIf="!isCreate && operationsForm.value.taxes_applicable == '0'">
                                    <small>Up on saving changes, Existing Tax Information for item will be removed
                                    </small>
                                </p>
                                <div class="form-error-message"></div>

                            </div>



                        </div>

                        <div class="form-row">

                            <div class="col-md-4 mb-3">

                                <label for="unit_id">Unit of measurement</label>
                                <select class="form-control" id="unit_id" type="text" placeholder="Unit of measurement"
                                    formControlName="unit_id" name="unit_id">
                                    <option value="-1">NA</option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.units)"
                                        [value]="dataHolderService.units[key].id">
                                        {{ dataHolderService.units[key].fullform }} (
                                        {{ dataHolderService.units[key].symbol }} )
                                    </option>
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.unit_id == '-1'">
                                        *Please select Unit of measurement
                                    </span>
                                </div>

                                <p><a routerLink="/settings/units/create"><small>Add new Unit of measurement
                                        </small></a></p>





                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="quantity">No. of Units </label>

                                <input class="form-control" id="quantity" step="any" type="number"
                                    placeholder="Quantity" formControlName="quantity" name="quantity">



                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['quantity'].errors?.required">
                                        *required</span>
                                </div>
                            </div>


                            <div class="col-md-4 mb-3">
                                <label for="price"> Price <small> (Of all Units and with out taxes ) </small> </label>

                                <input class="form-control" id="price" step="any" type="number" placeholder="Price"
                                    formControlName="price" name="price">

                                <ng-container *ngIf="operationsForm.value.price > 0">
                                    <p><small>{{ operationsForm.value.price | indianNumberFormat  }}</small>
                                        <small> (
                                            {{ operationsForm.value.price | numberToIndianWords  }} )
                                        </small>
                                    </p>

                                </ng-container>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['price'].errors?.required">
                                        *required</span>
                                </div>
                            </div>



                        </div>


                        <div class="form-row">
                            <div class="col-md-12 mt-3 mb-3">
                                <label for="details"> Additional Notes : </label>
                                <p> <small> </small> </p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 preview-details">
                                <angular-editor id="details" [config]="dataHolderService.kolkovEditorConfig"
                                    formControlName="details"></angular-editor>

                            </div>
                        </div>


                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD ITEM' : 'UPDATE ITEM' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading" type="button"><span>{{ loading ? '' : ' REMOVE ITEM' }}</span></button>

                    </form>

                    <div class="card-body">
                        <app-display-tax-unit-values *ngIf="!isCreate && operationsForm.value.taxes_applicable == '1'"
                            [type_of_data]="type_of_data == '2' ? '21' : '7' " [table_column_id]="fetchedData.id"  [basePrice]="fetchedData.price" [totalTax]="fetchedData.calculated_total_tax"   >
                        </app-display-tax-unit-values>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>