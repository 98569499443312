import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AlertsService } from './../services/alerts.service';
import { DataHolderService } from './../services/data-holder.service';

@Component({
  selector: 'app-refresh-fields-data',
  templateUrl: './refresh-fields-data.component.html',
  styleUrls: ['./refresh-fields-data.component.scss']
})
export class RefreshFieldsDataComponent implements OnInit {

  constructor(
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  setMeta(response) {
    this.dataHolderService.userInfo = response['user'];
    this.dataHolderService.designations = response['designations'];
    this.dataHolderService.customFields = response['custom_fields'];
    this.dataHolderService.taxUnits = response['tax_units'];
    this.dataHolderService.labels = response['labels'];
    this.dataHolderService.application_configuration = response['application_configuration'];
    this.dataHolderService.units = response['units'];

    this.dataHolderService.available_booking_items = response['available_booking_items'];
    this.dataHolderService.labourer_types = response['labourer_types'];
    this.dataHolderService.labour_type_of_works = response['labour_type_of_works'];



    // console.log(this.dataHolderService);
  }


  loadAllData(): void {

    this.authService.refreshFieldsData().subscribe((response: any) => {
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.setMeta(response);
      this.alertsService.addAlert("success", response.message);


    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });

  }

}
